@import "../scss/vars/all";
.resources {
  max-width: 30em;
  margin: 0 0 4em;
}
.resource {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 5em;
  margin: 0 0 1em;
  padding: 1.25em 1em;
  text-decoration: none;
  color: black;
  border-radius: 0.5em;
  background: #f7f7f7;
  // background: $neutral-yellow;
  box-shadow: 0.15em 0.15em 0.25em rgba(0, 0, 0, 0.1);
  transition: background 0.2s ease-in-out;
  // Could be similar to footer-checkbox ..
  // background: change-color($brand-blue, $lightness: 90%, $saturation: 80%);
  // background: change-color($brand-blue, $lightness: 92%, $saturation: 50%);
  text-decoration: underline;
  &:hover {
    background: $neutral-yellow;
    svg {
      transform: scale(1.15);
    }
  }
  // small {
  //   display: block;
  //   text-transform: uppercase;
  //   opacity: 0.5;
  // }
  svg {
    // position: absolute;
    // top: 1em;
    // right: 0.5em;
    // display: block;
    width: 3em;
    transition: all 0.2s ease-in-out;
  }
}
