// DEBUGGING
// INSTANT LOAD
// Don't stress if it gets committed, as h4x0r-menu selector is localhost-only
.h4x0r-menu ~ * * {
  // opacity: 1 !important;
  // animation-delay: 0s !important;
}

.animate-all-children-zoom {
  > * {
    opacity: 0;
    animation: zoom-fade-in 1s forwards;
    @for $i from 0 to 40 {
      &:nth-child(#{$i}) {
        // 2s initial delay, which is only applicable for the Site.jsx index page listings
        // But then again that's the only place it's used so yeah
        animation-delay: #{($i * 0.3 + 2)}s;
      }
    }
  }
}
.animate-all-children-fade {
  > * {
    opacity: 0;
    animation: fade-in 1s forwards;
    @for $i from 0 to 40 {
      &:nth-child(#{$i}) {
        animation-delay: #{($i * 0.5)}s;
      }
    }
  }
}
.animate-all-children-slide {
  .animation-trigger & {
    animation: none;
    > * {
      opacity: 0;
    }
  }
  .animation-triggered & {
    > * {
      opacity: 0;
      animation: slide-in 1s forwards;
      @for $i from 0 to 40 {
        &:nth-child(#{$i}) {
          animation-delay: #{($i * 0.5)}s;
        }
      }
    }
  }
}
$graphic-animate-delay-after-header: 2.25;
.animate-all-children-slide-after-header {
  > *,
  > blockquote .pie path {
    // ^ Also animate blockquote pie paths, despite being hidden, for the sake of cssAnimationTriggerAfterSlideIn so they're triggered after this slide
    opacity: 0;
    animation: slide-in 1s forwards;
    @for $i from 0 to 40 {
      &:nth-child(#{$i}) {
        animation-delay: #{($graphic-animate-delay-after-header + $i * 0.5)}s;
      }
    }
  }
}
.animate-fade-in {
  opacity: 0;
  animation: fade-in 1.5s forwards;
}
.animations-hotspots-feet {
  .foot {
    // Client went for a greensock version instead, but perhaps one day.. (as this is better)
    // opacity: 0;
    // animation: slide-in 0.8s forwards;
    // animation-delay: 5s;
    // transform-origin: 0 50%;
    a {
      color: $brand-blue;
      transition: color 0.2s ease-out;
      &:hover {
        color: $brand-blue-light !important;
      }
      .graphic[class*="active--"] & {
        color: $brand-slate-light;
      }
    }
    #{$active-hotspots} {
      color: $brand-blue;
    }
  }
}
// @keyframes foot-in {
//   0% {
//     opacity: 0;
//   }
//   20% {
//     // transform: scale(1.1) translate(-5%, -8%);
//     transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 100, 0, 0, 1);
//   }
//   100% {
//     opacity: 1;
//     // transform: scale(1) translate(0);
//     transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
//   }
// }
