/*
Note: Unum Slate (#5E88A1) text color may ONLY be used as title heading, heading, subheading, or copy above 19px/semibold. Unum Slate (#5E88A1) as a background must use white (#FFFFFF) text. The dark neutral (#525252) may be used in all copy.
*/
.-musculoskeletal-disorders [class*=svg--] text[data-fill=theme] {
  fill: #525252 !important;
}

.continuum aside {
  color: #015193;
}
@media (min-width: 820px) {
  .continuum aside {
    flex-grow: 1;
    order: 2;
    width: 40%;
    max-width: 420px;
    padding-left: 2em;
  }
}
.continuum aside h4 {
  margin-top: 0;
  font-size: inherit;
  font-weight: 600;
}
.continuum aside ._quote {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.5;
}
.continuum aside .correctnesses {
  margin-bottom: 2em;
}
.continuum aside .correctness {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  font-weight: bold;
}

continuum-svg {
  max-width: 660px;
  user-select: none;
}
continuum-svg .axis_label {
  text-transform: uppercase;
}
continuum-svg text {
  transition: fill 0.5s;
}
continuum-svg .axis_label {
  font-size: 14px;
}
continuum-svg .cls-1,
continuum-svg .cls-19,
continuum-svg .answer text,
continuum-svg .axis_label {
  fill: #004d8e;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
}
continuum-svg .cls-10,
continuum-svg .cls-11,
continuum-svg .cls-14,
continuum-svg .cls-17,
continuum-svg .cls-8,
continuum-svg .cls-9 {
  fill: none;
}
continuum-svg .cls-10,
continuum-svg .cls-11,
continuum-svg .cls-8,
continuum-svg .cls-9 {
  stroke: #004d8e;
  stroke-linecap: round;
  stroke-linejoin: round;
}
continuum-svg .cls-11,
continuum-svg .cls-8,
continuum-svg .cls-9 {
  stroke-width: 2px;
}
continuum-svg .cls-9 {
  stroke-dasharray: 0.51 12.36;
}
continuum-svg .cls-11 {
  stroke-dasharray: 0.52 12.55;
}
continuum-svg .quadrant_label {
  font-size: 14px;
  text-transform: none;
  fill: #5a7184;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
}
continuum-svg .cls-14 {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 7px;
}
continuum-svg .tick,
continuum-svg .cross {
  opacity: 0;
  fill: #fff;
}
continuum-svg .answer {
  color: #cee3f1;
  transition: color 0.5s, opacity 0.5s;
  cursor: pointer;
}
continuum-svg .answer text {
  font-size: 28px;
  transition: fill 0.5s;
  fill: #01335c;
}
continuum-svg.animating {
  pointer-events: none;
}
continuum-svg.animating .answer text {
  fill: #fff;
}
continuum-svg.animating .answer .cls-17 {
  stroke-width: 0;
}
.-init continuum-svg .line_segment,
.-init continuum-svg .axis_label,
.-init continuum-svg .answer,
.-init continuum-svg .quadrant_label {
  opacity: 0;
}
.-init continuum-svg .answer {
  cursor: default;
}
.-init continuum-svg .answer text {
  fill: white;
}
.-questions continuum-svg .answer {
  color: #c1cf01;
}
.-questions continuum-svg .answer,
.-questions continuum-svg .answer text {
  opacity: 1 !important;
}
.-questions continuum-svg .answer .cls-17 {
  fill: #fff;
  stroke: #fff;
  stroke-width: 2px;
}
.-questions continuum-svg .answer:hover, .-questions continuum-svg .answer:focus, .-questions continuum-svg .answer:active {
  color: #e89527;
}
.-questions continuum-svg .answer:hover text, .-questions continuum-svg .answer:focus text, .-questions continuum-svg .answer:active text {
  fill: #01335c;
}
continuum-svg [data-answer-state=unchosen] {
  color: #c1cf01;
  opacity: 0.4 !important;
  transform: scale(1) !important;
}
continuum-svg [data-answer-state=unchosen] .cls-17 {
  fill: #fff;
}
continuum-svg [data-answer-state=chosen-and-correct],
continuum-svg [data-answer-state=unchosen-but-correct] {
  cursor: default;
}
continuum-svg [data-answer-state=chosen-and-correct] text,
continuum-svg [data-answer-state=unchosen-but-correct] text {
  opacity: 0;
}
continuum-svg [data-answer-state=chosen-and-correct], continuum-svg [data-answer-state=chosen-and-correct]:hover, continuum-svg [data-answer-state=chosen-and-correct]:focus, continuum-svg [data-answer-state=chosen-and-correct]:active,
continuum-svg [data-answer-state=unchosen-but-correct],
continuum-svg [data-answer-state=unchosen-but-correct]:hover,
continuum-svg [data-answer-state=unchosen-but-correct]:focus,
continuum-svg [data-answer-state=unchosen-but-correct]:active {
  color: #8cc442;
}
continuum-svg [data-answer-state=chosen-and-correct] .cls-17,
continuum-svg [data-answer-state=unchosen-but-correct] .cls-17 {
  fill: currentColor;
  stroke: #fff;
  stroke-width: 2px;
}
continuum-svg [data-answer-state=chosen-and-correct] .cross,
continuum-svg [data-answer-state=unchosen-but-correct] .cross {
  opacity: 0;
}
continuum-svg [data-answer-state=chosen-and-correct] .tick,
continuum-svg [data-answer-state=unchosen-but-correct] .tick {
  opacity: 1;
}
continuum-svg [data-answer-state=chosen-but-incorrect] {
  cursor: default;
}
continuum-svg [data-answer-state=chosen-but-incorrect] text {
  opacity: 0;
}
continuum-svg [data-answer-state=chosen-but-incorrect], continuum-svg [data-answer-state=chosen-but-incorrect]:hover, continuum-svg [data-answer-state=chosen-but-incorrect]:focus, continuum-svg [data-answer-state=chosen-but-incorrect]:active {
  color: #e89527;
}
continuum-svg [data-answer-state=chosen-but-incorrect] .cls-17 {
  fill: currentColor;
  stroke: #fff;
  stroke-width: 2px;
}
continuum-svg [data-answer-state=chosen-but-incorrect] .tick {
  opacity: 0;
}
continuum-svg [data-answer-state=chosen-but-incorrect] .cross {
  opacity: 1;
}

.continuum ._icon::before {
  display: inline-block;
  width: 3em;
  height: 3em;
  margin-right: 1em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60% auto;
  border-radius: 50%;
  content: "";
  transition: background 0.15s ease-out;
}
.continuum ._icon.-check::before {
  background-color: #cee3f1;
  background-image: url("data:image/svg+xml, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='35px' height='35px' viewBox='0 0 34.6 26.4' style='enable-background:new 0 0 34.6 26.4;' xml:space='preserve' fill='%23015193'%3E %3Cpath class='st0' d='M12.1,26.4h-0.3c-1.1,0-2.1-0.5-2.7-1.4c-2.3-3.1-5-5.9-8-8.4c-1.3-1.1-1.4-3-0.3-4.2c0,0,0,0,0.1-0.1 c0.9-1.3,2.6-1.6,3.9-0.8c0.1,0,0.1,0.1,0.2,0.1c2.3,1.8,4.5,3.9,6.5,6.1c3.4-4.7,10.1-12.3,18.6-17c1.5-0.8,3.4-1.4,4.4,0.5 S33.5,4.6,32,5.5c-10.8,6-17.6,19.4-17.7,19.5C13.8,25.7,13,26.3,12.1,26.4z'/%3E %3C/svg%3E");
}
.continuum ._icon.-correct::before {
  background-color: #c1cf01;
  background-image: url("data:image/svg+xml, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='35px' height='35px' viewBox='0 0 34.6 26.4' style='enable-background:new 0 0 34.6 26.4;' xml:space='preserve' fill='%23fff'%3E %3Cpath class='st0' d='M12.1,26.4h-0.3c-1.1,0-2.1-0.5-2.7-1.4c-2.3-3.1-5-5.9-8-8.4c-1.3-1.1-1.4-3-0.3-4.2c0,0,0,0,0.1-0.1 c0.9-1.3,2.6-1.6,3.9-0.8c0.1,0,0.1,0.1,0.2,0.1c2.3,1.8,4.5,3.9,6.5,6.1c3.4-4.7,10.1-12.3,18.6-17c1.5-0.8,3.4-1.4,4.4,0.5 S33.5,4.6,32,5.5c-10.8,6-17.6,19.4-17.7,19.5C13.8,25.7,13,26.3,12.1,26.4z'/%3E %3C/svg%3E");
}
.continuum ._icon.-incorrect::before {
  background-color: #e89527;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='35px' height='35px' viewBox='0 0 28.3 24.3' style='enable-background:new 0 0 28.3 24.3;' xml:space='preserve' fill='%23fff'%3E %3Cpath class='st0' d='M18.6,11.9c0.3,0.3,5.3,5.2,7.5,7.4c1.1,1.1,1.1,2.9,0.1,4.1c-1,1.1-2.7,1.3-3.8,0.3c-0.1-0.1-0.1-0.1-0.2-0.2 c-1.6-1.6-3.2-3.2-4.9-4.8c-0.9-0.9-1.9-1.8-2.9-2.7c-0.2-0.2-0.4-0.2-0.6,0c-2.4,2.2-4.8,4.5-7.1,6.9c-0.2,0.2-0.5,0.5-0.7,0.7 c-1,1-2.7,1-3.7,0c-1-1-1.2-2.5-0.5-3.7c0.1-0.2,0.3-0.4,0.5-0.6l7.1-7c0.3-0.3,0.3-0.3,0-0.6L2.5,6.3C1.9,5.9,1.5,5.5,1,5.1 c-1.2-1-1.3-2.8-0.3-4C0.8,1,0.9,0.9,1.1,0.8c1.2-0.9,2.8-1,4-0.1c2.9,2.2,5.8,4.4,8.5,6.8c0.6,0.5,0.4,0.5,1,0 c2.8-2.3,5.6-4.6,8.5-6.8c1-0.8,2.4-0.9,3.6-0.3c1.4,0.7,2,2.3,1.3,3.7c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.4-0.6,0.7-0.9,1L18.6,11.9z'/%3E %3C/svg%3E");
  background-size: 50% auto;
}
.continuum .quiz-button {
  display: inline-flex;
  align-items: center;
  padding: 1.3em;
  font-size: 0.8em;
  font-weight: 700;
  color: #c1cf01;
  text-transform: uppercase;
  background: white;
  border: 2px solid;
  border-radius: 5em;
  outline: none;
  user-select: none;
}
.continuum .quiz-button.-check, .continuum .quiz-button.-next {
  animation: pulse 3s linear infinite;
}
.continuum .quiz-button._icon {
  padding: 0.6em 1em 0.6em 0.4em;
  filter: hue-rotate(0deg);
}
.continuum .quiz-button:hover::before, .continuum .quiz-button:focus::before {
  background-color: #c1cf01;
  background-image: url("data:image/svg+xml, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='35px' height='35px' viewBox='0 0 34.6 26.4' style='enable-background:new 0 0 34.6 26.4;' xml:space='preserve' fill='%23fff'%3E %3Cpath class='st0' d='M12.1,26.4h-0.3c-1.1,0-2.1-0.5-2.7-1.4c-2.3-3.1-5-5.9-8-8.4c-1.3-1.1-1.4-3-0.3-4.2c0,0,0,0,0.1-0.1 c0.9-1.3,2.6-1.6,3.9-0.8c0.1,0,0.1,0.1,0.2,0.1c2.3,1.8,4.5,3.9,6.5,6.1c3.4-4.7,10.1-12.3,18.6-17c1.5-0.8,3.4-1.4,4.4,0.5 S33.5,4.6,32,5.5c-10.8,6-17.6,19.4-17.7,19.5C13.8,25.7,13,26.3,12.1,26.4z'/%3E %3C/svg%3E");
}

@media (min-width: 820px) {
  .continuum {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.continuum.-init aside {
  opacity: 0;
}
.continuum h3 {
  margin-bottom: 0.5em;
}
.continuum ._abcd {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.3em;
  height: 2.3em;
  margin-left: 0.4em;
  font-weight: bold;
  border: 2px solid #c1cf01;
  text-align: center;
  border-radius: 50%;
}
.continuum ._correct-answer-is {
  margin-bottom: 0;
}