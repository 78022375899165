[class^="logo"] {
  // Use aria-label="xxxx" instead of adding text into the <a>
  display: inline-block;
  width: 100%;
  max-width: 10em;
  svg,
  img {
    width: 100%;
    height: auto;
  }
}
// Add a modifier class to the logo in a footer, for example
// .logo--footer {
//   max-width: 6em;
// }
.logo--header {
  text-decoration: none;
  color: grey;
  text-transform: uppercase;
  font-size: 2em;
}
