blockquote,
cite {
  display: block;
  max-width: 680px;
  padding-left: 2rem;
}
blockquote {
  @include h2;
  font-size: 1.8em;
  margin: 2em 0 0;
  padding-bottom: 0.5em;
  line-height: 1.3;
  border-left: 1px solid $brand-blue;
  [class^="icon"],
  .pie {
    width: 6em;
    float: right;
    background: none;
    border: none;
    padding: 0 0 0 1em;
  }
}
cite {
  font-style: normal;
  font-size: 1em;
  margin: 0 0 3em;
  // color: adjust-color($neutral-dark, $lightness: 15%);
  color: $brand-slate-light;
  border-left: 1px solid $brand-blue;
  .banner p & {
    margin: 0.5em 0 1em;
    border-color: $neutral-blue;
    &,
    a {
      // color: $neutral-dark;
    }
  }
  .graphic_text & {
    margin-bottom: 1em;
  }
}
.inner > blockquote:first-child {
  // Lift up a blockquote if it's the first thing in content, e.g. /cancer-in-the-workplace/modules/1
  margin-top: 0;
}
.cite-generic {
  padding: 0;
  li {
    max-width: 600px;
    margin: 0 0 1em;
    font-size: 0.8em;
    font-style: normal;
    padding-left: 1.5em;
    position: relative;
    z-index: 0;
    &[data-prefix] {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: attr(data-prefix);
        border: 0;
      }
    }
  }
}
