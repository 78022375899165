.cards {
  @media (min-width: 769px) {
    display: flex;
  }
  + h3 {
    margin-top: 1em;
  }
}
.card {
  border: 1px solid $neutral-blue;
  padding: 0.5em 2em;
  margin: 0 0 2em;
  margin-right: 2em;
  background: white;
  h2,
  h3,
  h4,
  h5 {
    margin: 0.5em 0 0.75em;
  }
  cite {
    font-size: 0.8em;
    padding-left: 1em;
  }
  ul {
    margin-bottom: 1em;
  }
}
