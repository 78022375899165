.oblong {
  position: relative;
  z-index: 10;
  padding: 2em 0;
  margin: 2em -5vw 2em;
  min-height: 400px;
  color: white;
  background: $brand-blue;
  display: flex;
  flex-flow: row; // has to be row for IE11 fix
  justify-content: center;
  // border-radius: 0.5em;
  @media (min-width: 820px) {
    padding: 3em 4em 3em 0;
    margin: 3.2em 0;
    border-radius: 0 10em 10em 0;
    border-radius: 0 23em 23em 0/0 30em 30em 0;
    &.-sum {
      padding-right: 10em;
    }
  }
  &::before {
    @media (min-width: 820px) {
      position: absolute;
      top: 0;
      right: 98%;
      bottom: 0;
      z-index: -1;
      width: 100vw;
      background: inherit;
      content: "";
    }
  }
  // IE11 fix - a row element that forces the min height
  &::after {
    display: block;
    width: 0;
    min-height: inherit;
    content: "";
  }
  &.-row-centered {
    align-items: center;
    flex-flow: row;
    @media (max-width: 820.5px) {
      padding-right: 5vw;
      padding-left: 5vw;
    }
  }
  &.-count-2 {
    max-width: 900px;
    h3 {
      font-size: 1em;
    }
  }
  h2,
  h3,
  h4,
  h5,
  blockquote {
    color: white;
    @media (min-width: 820px) {
      padding-right: 0;
      padding-left: 0;
    }
  }
  > h2,
  > h3 {
    padding-right: 0.5em;
    padding-left: 1em;
    margin-top: 0.7em;
  }
  blockquote {
    margin-top: 0;
  }
  cite {
    margin-bottom: 0;
    + & {
      margin-top: 3em;
    }
  }
  .text_wrapper {
    max-width: 50em;
  }
}
