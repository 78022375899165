@mixin wrapper {
  // Reusable wrapper
  width: 90%;
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
}
[class^="wrapper"] {
  @include wrapper;
}
.wrapper {
  &--large {
    max-width: 1400px;
  }
  @media (min-widht: 769px) {
    width: 95%;
  }
  main > & {
    padding-top: 0.2em;
  }
}
