$debug: false !default; // Change to true to add design overlay as a background image to line up elements accurately

html {
  height: 100%;
  font-size: 62.5%;
  @if ($debug) {
    // Debug styles
    background: url("https://s3.invisionapp-cdn.com/storage.invisionapp.com/screens/files/337115153.jpg?x-amz-meta-iv=3&response-cache-control=max-age%3D2419200&x-amz-meta-ck=978f58edb17fe471b347e15fdf9b21a5&AWSAccessKeyId=AKIAJFUMDU3L6GTLUDYA&Expires=1548979200&Signature=lTehz3aY7h7tWfVdIX%2FuNjU9twQ%3D")
      center -84px
      no-repeat;
    body {
      background: red;
      opacity: 0.5;
    }
  }
}
body {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  // min-height: 100%;
  color: $neutral-dark;
}
#root {
  opacity: 0;
  animation: fade-in 1s forwards;
  min-height: 201px;
  height: 100%;
  overflow: auto;
}
main {
  position: relative;
  width: 100%;
  z-index: 1;
}
