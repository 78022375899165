// <header role="banner"> describes the site header and is a direct child of <body>. Role="banner" distinguishes it from other <header>s in the site
[role="banner"] {
  @include wrapper;
  display: flex;
}
.masthead {
  height: 85px;
  padding: 1.2em 0;
  background: white;
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
  a {
    text-align: left;
  }
  img {
    width: 100%;
    max-width: 140px;
  }
  .affiliate-logos {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    // max-width: 300px;
    // max-width: 60%;
    div {
      max-width: 50%;
      margin-left: 2em;
    }
    img {
      display: block;
      // width: auto;
      // max-width: 100%;
      // max-height: 45px;
      width: 100%;
      max-width: 160px;
      // max-height: 45px;
      height: auto;
      // margin: 0 0 0 2em;
      margin: 0;
    }
    div ~ div img {
      max-width: 130px;
    }
  }
}
