nav {
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  a {
    text-decoration: none;
    user-select: none;
  }
}
.nav--header {
  ul {
    align-items: center;
    justify-content: flex-end;
  }
  li {
    max-width: 120px;
    padding: 0.5em;
    font-size: 0.7em;
    text-align: center;
  }
  a {
    // Use the theme colours on an element (see utilities)
    // @include apply-theme-to-attribute(color, small);
    color: gray;
  }
  small {
    display: block;
  }
  [aria-current="true"] {
    text-decoration: underline;
  }
}
.nav--content {
  margin-top: 6em;
  font-size: 0.55em;
  @media (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
  }
  > * {
    width: 100%;
    // min-width: 43%;
    max-width: 480px;
    // margin-right: 4%;
  }
}
.nav--index {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
}
