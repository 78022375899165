/**
 * responsive-value
 * Scale a property with viewport width, but constrained with a min/max size and viewport width, e.g.

    // Scale font from 12px -> 18px between 1000px and 2000px viewport width
    body {
        @include responsive-value(
            $size-min: 12px,
            $size-max: 18px,
            $width-min: 768px,
            $width-max: 2000px
        );

        // Shorthand
        @include responsive-value(12px, 18px, 768px, 2000px);

        // Other properties (as it defaults to font-size)
        @include responsive-value(12px, 18px, 768px, 2000px, 'margin-bottom');
     }
 * NOTE: Must all be pixel values!
 */

@mixin responsive-value(
  $size-min,
  $size-max,
  $width-min: 768px,
  $width-max: 2000px,
  $property: "font-size"
) {
  // Min size
  #{$property}: $size-min;

  // Scaling within constraints
  @media screen and (min-width: $width-min) {
    #{$property}: calc(
      #{$size-min} + #{strip-unit($size-max - $size-min)} *
        ((100vw - #{$width-min}) / #{strip-unit($width-max - $width-min)})
    );
  }

  // Max size
  @media screen and (min-width: $width-max) {
    #{$property}: $size-max;
  }
}

// Required for the above
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

/**
 * fa
 * Font-awesome icon - inject as a ::before or ::after pseudo element
 *
 * @param  string   $char      Font-awesome character (e.g. $fa-var-envelope)
 * @param  string   $position  [before/after] (default: before)
 * @param  content  {}         Styles that apply to the icon
 *
 * List of font-awesome variables (although they're guessable):
 * https://github.com/FortAwesome/Font-Awesome/blob/master/scss/_variables.scss

*/

// @import "../../../node_modules/@fortawesome/fontawesome-free/scss/variables";
// @mixin fa($char: null, $position: before) {
//   // $char content can be empty, say if inheriting from a parent
//   @if ($char != null) {
//     &:#{$position} {
//       content: fa-content($char);
//     }
//   }
//   &:#{$position} {
//     display: inline-block;
//     font-family: "Font Awesome 5 Free";
//     font-style: normal;
//     // Solid icons (bold) are entirely available in the free version.
//     // Some also have a regular 400/light 300 version but is rarer, so default otherwise:
//     font-weight: 900;
//     font-display: block;
//     // underline must be set, then unset - ie8 fix
//     text-decoration: underline;
//   }
//   &:#{$position} {
//     text-decoration: none;
//     @content;
//   }
// }

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @return if(
    $index,
    str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      ),
    $string
  );
}

// SVG
// Encode SVG XML into as CSS-friendly format
// (requires str-replace, above)
//
// Usage, for content or background-image:
//   .foo {
//     content: svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg"
//      width="100%" viewBox="0 0 160 160">
//       <circle cx="80" cy="80" r="80" fill="red" />
//     </svg>'
//   );
//
@import "~svg-sass";

//
// apply-theme-to-attribute
// Use the theme colours on an element
//
// Usage:
//   @include apply-theme-to-attribute(background-color);
//
// This would creates all the various rules, such as
//   [class*="-what-is-stress"] {
//     background-color: $f00ba7
//   }
//
// To specify an element within the current element:
//   @include apply-theme-to-attribute(color, h1)
//
@mixin apply-theme-to-attribute($attribute, $element: "&") {
  @each $theme, $color in $themes {
    [class*="-#{$theme}"] & {
      #{$element} {
        #{$attribute}: $color !important;
      }
    }
  }
}
// ^ Notes For future ref
// $themes are created like so:
// $themes: (
//   ("what-is-stress" $brand-6),
//   ("why-manage-stress" $brand-3) )
// Also, one day this will be unnecessary as content args will be legit https://github.com/sass/language/blob/master/accepted/content-args.md#syntax
// Something like
// @mixin themes() {
//   @each $theme, $color in $themes {
//     [class*="-#{$theme}"] {
//       @content ($color);
//     }
// }
// @include themes using ($color) {
//   small {
//     color: $color;
//   }
// }
@mixin icon--right($fg: #ffffff) {
  display: inline-block;
  content: svg(
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%"  viewBox="0 0 9 14.9" enable-background="new 0 0 9 14.9"><polyline fill="none" stroke="#{$fg}" stroke-width="3" stroke-linecap="round" stroke-miterlimit="10" points="1.5,1.5 7,7.5 1.6,13.4" /></svg>'
  );
  width: 0.6em;
  @content;
}
@mixin icon--left {
  @include icon--right;
  transform: scaleX(-1);
  @content;
}
@mixin icon--info($fg: #ffffff) {
  display: inline-block;
  content: svg(
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 14" style="enable-background:new 0 0 14 14;" xml:space="preserve"><style type="text/css">.st0{fill:#{$fg};}.st1{fill:none;stroke:#FFFFFF;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;}</style><g id="Layer_1"><path class="st0" d="M7,13.5c-3.6,0-6.5-2.9-6.5-6.5S3.4,0.5,7,0.5s6.5,2.9,6.5,6.5S10.6,13.5,7,13.5"/></g><g id="Layer_2"><line class="st1" x1="7" y1="6.6" x2="7" y2="10.5"/><line class="st1" x1="7" y1="4" x2="7" y2="3.9"/></g></svg>'
  );
  width: 2.5em;
  height: 2.5em;
  @content;
}
@mixin icon--right-thin($fg: #ffffff) {
  display: inline-block;
  content: svg(
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%"  viewBox="0 0 9 14.9" enable-background="new 0 0 9 14.9"><polyline fill="none" stroke="#{$fg}" stroke-width="1" stroke-linecap="round" stroke-miterlimit="10" points="1.5,1.5 7,7.5 1.6,13.4" /></svg>'
  );
  width: 0.6em;
  @content;
}
@mixin icon--right-fat($fg: #ffffff) {
  display: inline-block;
  content: "";
  width: 2em;
  height: 2em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 979.9 811.8' style='enable-background:new 0 0 979.9 811.8;' xml:space='preserve'%3E%3Cpath d='M115.9,521.9h420L444,613.8c-45.3,45.3-45.3,118.8,0,164c45.3,45.3,118.7,45.3,164,0l371.9-371.9L608,34 c-22.6-22.7-52.3-34-82-34c-29.7,0-59.3,11.3-82,34c-45.3,45.3-45.3,118.8,0,164l91.9,91.9h-420C51.9,289.9,0,341.8,0,405.8 c0,64.1,51.9,115.9,115.9,115.9V521.9z' fill='%23#{str-replace('#{$brand-blue}', '#', '')}' /%3E%3C/svg%3E%0A");
  @content;
}
@mixin icon--right-round($fg: #ffffff) {
  display: inline-block;
  content: svg(
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 38 38"><style type="text/css">.st0{fill:#015294;}.st1{fill:#FFFFFF;stroke:#FFFFFF;}.st2{fill:none;stroke:#FFFFFF;}</style><g transform="translate(419.000000, 20.000000)"><circle id="Oval" class="st0" cx="-400" cy="-1" r="19"></circle><path id="Path-3" class="st1" d="M-396.3-1h-11H-396.3v-3l2.7,3l-2.7,3V-1z"></path></g></svg>'
  );
  width: 2.5em;
  height: 2.5em;
  @content;
}
