// A subset of styles from Modern Normalize
// https://github.com/sindresorhus/modern-normalize/blob/master/modern-normalize.css

* {
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

body {
  // Remove default margins and center
  margin: 0 auto;
}

// IE11 doesn't recognise html5 elements as block by default unlike newer browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
mark,
nav,
section,
summary,
time {
  display: block;
}

button {
  border-width: 0;
  border-radius: 0;
  line-height: inherit;
  &:not(:disabled) {
    cursor: pointer;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

textarea {
  vertical-align: top; // Removes whitespace underneath
}

img,
svg {
  display: block;
  max-width: 100%;
}

img {
  height: auto;
}
svg {
  max-height: 100%; // Fixes Safari bug
  &:not(:root) {
    // Show content outside of SVG viewbox so it can be seen when animated
    overflow: visible;
  }
}

// Stop Webkit (Safari) forcing rounded borders
// https://stackoverflow.com/a/2918716
input[type="search"] {
  -webkit-appearance: textfield;
}

// Stop Webkit (Safari) forcing widths and whatnot on date inputs
// https://stackoverflow.com/a/31523871
input[type="date"] {
  // Fixes Safari date input appearance
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

// Table vertical alignment default to top, as browsers are inconsistent
th,
td {
  vertical-align: top;
}

/**
 * Add the correct text decoration in Chrome, Edge, and Safari.
 */

abbr[title] {
  text-decoration: underline dotted;
}

// Correct default font weight

strong {
  font-weight: 600;
}

// Prevent <sub> and <sup> elements from affecting the line height in all browsers

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
