@use "../base/utility";
.pagination {
  list-style-type: none;
  padding: 0;
  margin: 0;
  a {
    text-decoration: none;
    color: #fff;
    display: none;
    text-align: center;
    user-select: none;
    &::before {
      content: "Module ";
    }
    span {
      &::before {
        content: ": ";
      }
    }
    &.active {
      cursor: default;
      display: block;
    }
    // disabled
    &[disabled] {
      cursor: default;
    }
  }
  @media (min-width: 500px) {
    display: flex;
    padding-left: 1em;
    padding-right: 1em;
    // If the container isn't hovered, set some default styles
    // https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-within
    &:not(:hover) {
      a span {
        opacity: 0;
      }
      .active {
        span {
          opacity: 1;
        }
      }
    }
    li {
      a {
        span {
          opacity: 0;
        }
        // The hovered element
        &:hover {
          span {
            opacity: 1;
          }
        }
        &[style*="pointer-events: none"]:not([aria-current]) {
          // disabled (sorry for lazy rushiness)
          opacity: 0.5;
        }
      }
      // Everything after the hovered element
      &:focus ~ li,
      &:hover ~ li {
        a {
          span {
            opacity: 0;
          }
        }
      }
      &:nth-child(odd) {
        // Move module label below on odd-numbered modules <span>
        span {
          top: auto;
          bottom: 0;
          transform: translate(-50%, 110%);
        }
      }
    }
    a {
      display: block;
      margin: 0 0.2em;
      transition: all 0.1s ease-out;
      position: relative;
      z-index: 10;
      border-radius: 50%;
      width: 2.4em;
      line-height: 2.4;
      &::before {
        content: none;
      }
      &::after {
        content: "";
        transition: transform 0.5s, background-color 0.5s;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        border: 2px solid transparent;
      }
      span {
        transition: opacity 0.5s;
        font-size: 1rem;
        line-height: 1.3;
        bottom: auto;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 50%;
        transform: translate(-50%, -110%);
        white-space: nowrap;
        user-select: none;
        opacity: 0;
        &::before {
          content: none;
        }
      }
      // &:focus, // sacrificing some tab accessibility for #3507
      &:hover {
        &::after {
          border-color: #fff;
        }
      }
    }
    .active {
      color: $brand-blue;
      cursor: default;
      &::after {
        background-color: #fff;
        transform: scale(1);
      }
      span {
        color: #fff;
      }
    }
  }
  @media (min-width: 769px) {
    a {
      span {
        font-size: 1.2rem;
      }
      &::after {
        transform: scale(0.6);
      }
      &:hover {
        span {
          opacity: 1;
        }
      }
      &:hover,
      &:focus {
        &::after {
          transform: scale(0.8);
        }
      }
      &:active {
        color: $brand-blue;
        &::after {
          transform: scale(0.9);
          background-color: #fff;
        }
        span {
          color: #fff;
          opacity: 1;
        }
      }
    }
  }
}
[class^="nav-item"] {
  color: white;
  text-decoration: underline;
  font-size: 0;
  // border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  // border: 2px solid;
  flex-shrink: 0;
  user-select: none;
  display: flex;
  align-items: center;
  &::before,
  &::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
  }
  &::after {
    margin-right: 0;
    margin-left: 0.5em;
  }
  span {
    display: none;
  }
  i {
    display: block;
    font-style: normal;
    font-size: 0.8em;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
  &[class$="--prev"] {
    &::before {
      @include icon--left;
      height: 2.5rem;
      transform: scaleX(-1) translateX(0.2rem);
    }
  }
  &[class$="--next"] {
    text-align: right;
    margin-left: auto;
    justify-content: flex-end;
    &::after {
      @include icon--right;
      height: 2.5rem;
      transform: translateX(0.2rem);
    }
  }
  @media (min-width: 900px) {
    font-size: 0.9em;
    height: auto;
    width: auto;
    // border-width: 0;
    // border-radius: 0;
    line-height: inherit;
    &::before,
    &::after {
      width: auto;
      margin-right: 0.5em;
      transition: transform 0.2s ease-out;
    }
    &:hover,
    &:focus {
      &::before {
        transform: scaleX(-1) translateX(0.5em);
      }
      &::after {
        transform: translateX(0.5em);
      }
    }
    span {
      display: block;
    }
    &[class$="--prev"] {
      &::before {
        height: auto;
      }
    }
    &[class$="--next"] {
      &::after {
        height: auto;
      }
    }
  }
  @media (min-width: 450px) and (max-width: 900px) {
    &[class$="--prev"] {
      transform: translateX(-1em);
    }
    &[class$="--next"] {
      transform: translateX(1em);
    }
  }
}
