[class^="icon--clock"] {
  .clock_hand {
    $animation-duration: 3;
    transform-origin: 88.7px 144.7px;
    animation: rotate-in #{$animation-duration}s 0s both;
  }
  .hold-animation & {
    .clock_hand {
      animation-play-state: paused;
    }
  }

  @keyframes rotate-in {
    0% {
      transform: rotate(-360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

.icon--clock {
  &_large {
    .clock_hand {
      transform-origin: 275px 158.7px;
    }
  }
}
