.breadcrumbs {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 0.8em;
  color: $brand-blue;
  margin: 1.7em 0;
  li::before {
    content: none;
  }
  a {
    font-weight: 700;
    color: inherit;
  }
  li:not(:last-child) {
    &:after {
      content: "/";
      margin-right: 0.4em;
      margin-left: 0.4em;
    }
  }
}
