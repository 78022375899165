.triple-whammy {
  // Been through so, so many versions at this pont, I can't even
  padding: 2em;
  background: $neutral-blue;
  border-radius: 0.4em;
  font-size: 1.3em;
  color: black;
  span {
    margin-right: 1em;
    opacity: 0;
  }
  &.triggered span {
    animation: slide-in 0.5s forwards;
    @for $i from 1 to 9 {
      &:nth-child(#{$i}) {
        animation-delay: #{($i - 1) * 0.5}s;
      }
    }
  }
  ._operator {
    font-weight: 900;
    color: $brand-blue-dark;
  }
}
