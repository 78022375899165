@import "../vars/all";
.button,
[role="button"],
[type="submit"] {
  text-decoration: none;
  padding: 0.5em 1em;
  display: inline-block;
  background-color: red;
  transition: background-color 0.5s;
  border-radius: 0.2em;
  font-size: inherit;
  &:hover,
  &:active,
  &:focus {
    outline: none;
    // Set a hover style instead of outline
    background-color: darken(red, 10);
  }
}
.button.-modal {
  background-color: $brand-blue;
  color: white;
  &:hover,
  &:active,
  &:focus {
    background-color: $brand-blue-light;
  }
}
