$active-hotspots: ".active--0 & a.hotspot--0,.active--1 & a.hotspot--1,.active--2 & a.hotspot--2,.active--3 & a.hotspot--3,.active--4 & a.hotspot--4,.active--5 & a.hotspot--5,.active--6 & a.hotspot--6,.active--7 & a.hotspot--7,.active--8 & a.hotspot--8,.active--9 & a.hotspot--9,.active--10 & a.hotspot--10,.active--b0 & a.hotspot--b0,.active--b1 & a.hotspot--b1,.active--b2 & a.hotspot--b2,.active--b3 & a.hotspot--b3,.active--b4 & a.hotspot--b4,.active--b5 & a.hotspot--b5,.active--b6 & a.hotspot--b6,.active--b7 & a.hotspot--b7,.active--b8 & a.hotspot--b8,.active--b9 & a.hotspot--b9";

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  svg {
    /* IE needs a fixed height */
    // height: 225px !important;
  }
}

@mixin hotspot-hover-greensock {
  .hotspot:hover,
  #{$active-hotspots} {
    text,
    .thumb.thumb {
      fill: #fff !important;
      // No time to do this differently!
    }
    path,
    circle,
    polygon {
      @include apply-theme-to-attribute(fill);
    }
  }
  use {
    fill: white;
  }
  .hotspot:hover,
  #{$active-hotspots} {
    use {
      @include apply-theme-to-attribute(fill);
    }
  }
}
@mixin hotspot-hover-pulse {
  // #{$active-hotspots} {
  //   // person with hotspots:
  //   .point,
  // // general ring graphic hotspots:
  // + .point {
  //     animation: pulse 1s infinite !important;
  //     animation-direction: alternate;
  //     transition-timing-function: ease-out;
  //     animation-delay: 0;
  //   }
  // }
  // @keyframes pulse {
  //   0% {
  //     transform: scale(1);
  //   }
  //   80% {
  //     transform: scale(1.3);
  //   }
  // }
}
@mixin hotspot-greensock {
  .hotspot {
    &,
    + .point {
      opacity: 0;
    }
  }
}
@mixin person-hotspots {
  .hotspot {
    circle[fill="hotpink"],
    path[fill="hotpink"] {
      fill: $brand-slate;
    }
    &:hover {
      circle[fill="hotpink"],
      path[fill="hotpink"] {
        @include apply-theme-to-attribute(fill);
      }
    }
  }
  #{$active-hotspots} {
    circle[fill="hotpink"],
    path[fill="hotpink"] {
      @include apply-theme-to-attribute(fill);
    }
  }
}
@mixin person-hotspots-colouring-new {
  .hotspot {
    circle[fill="hotpink"],
    path[fill="hotpink"] {
      @include apply-theme-to-attribute(fill);
    }
    &:hover {
      circle[fill="hotpink"],
      path[fill="hotpink"] {
        fill: $brand-slate-light;
      }
    }
  }
  #{$active-hotspots} {
    circle[fill="hotpink"],
    path[fill="hotpink"] {
      fill: $brand-blue-light;
    }
  }
}
@mixin graphic-point-origins($origins) {
  @each $x, $y, $i in $origins {
    $i: index($origins, ($x $y)) - 1;
    .hotspot--#{$i},
    .hotspot--#{$i} + .point {
      transform-origin: $x $y;
    }
  }
}
@mixin graphic-points-3 {
  @include graphic-point-origins((569px 235px, 965px 926px, 1362px 235px));
}
@mixin graphic-bubbles {
  @include graphic-point-origins((525px 283px, 945px 920px, 1367px 283px));
  .hotspot:hover,
  #{$active-hotspots} {
    g path {
      fill: $brand-blue !important;
    }
  }
}
@mixin graphic-points-4 {
  @include graphic-point-origins(
    (576px 195px, 576px 731px, 1314px 731px, 1314px 195px)
  );
}
@mixin graphic-points-5 {
  @include graphic-point-origins(
    (770px 93px, 605px 605px, 1039px 919px, 1473px 605px, 1311px 93px)
  );
}
@mixin graphic-points-6 {
  @include graphic-point-origins(
    (
      815px 79px,
      586px 468px,
      815px 871px,
      1272px 871px,
      1500px 475px,
      1272px 79px
    )
  );
}
@mixin graphic-points-8 {
  @include graphic-point-origins(
    (
      713px 414px,
      586px 740px,
      716px 1054px,
      1040px 1191px,
      1360px 1060px,
      1500px 740px,
      1367px 403px,
      1040px 270px
    )
  );
}
@keyframes fade-in {
  to {
    opacity: 1;
  }
}
