// @import "../vars/all";

.-left {
  // not same as above..
  float: left;
  width: 7em;
  margin-right: 1em;
}
// .icon--calendar,
// .icon--certificate,
// .icon--depression,
// .icon--legal,
// .icon--person {
[class^="icon--"] {
  max-width: 8em;
  .fill-brand,
  .st0 {
    fill: $brand-blue;
  }
  .fill-theme,
  .st1 {
    @include apply-theme-to-attribute(fill);
  }
  // .stroke-theme {
  //   @include apply-theme-to-attribute(stroke-color);
  // }
  .oblong & {
    .st0 {
      fill: white;
    }
  }
  &.-layers {
    position: relative;
    svg ~ svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.icon-row {
  display: flex;
  flex-flow: column;
  @media (min-width: 769px) {
    align-items: center;
    flex-flow: row;
  }
  > [class^="icon"] {
    margin: 2em auto 1em;
    width: 8em;
    flex-shrink: 0;
    @media (min-width: 769px) {
      margin: 0 2em;
    }
  }
  div:first-child {
    padding-right: 3%;
    padding-bottom: 3%;
  }
}
.icon--person {
  animation: jump 0.5s 4s ease-out;
  animation-iteration-count: 2;
  transform-origin: 50% 100%;
  padding: 0 !important;
  ._arm,
  ._shadow {
    animation: wave 0.4s 5s linear;
    animation-iteration-count: 3;
  }
  ._arm {
    transform-origin: 55% 38%;
  }
  ._shadow {
    transform-origin: 74% 30%;
  }
}
.icon--healthLayered {
  // Sorry about the camel case - it's React's class
  overflow: hidden;
  .handle {
    opacity: 0;
    animation: slide-in 1s 3.5s both;
  }
  .body {
    animation: zoom-fade-in 1s 3s both;
  }

  .symbol {
    opacity: 0;
    animation: zoom-fade-in 1s 3.5s both;
  }
}

.icon--stressLayered {
  svg {
    opacity: 0;
    animation: zoom-fade-in 1s both;
    &:nth-child(4) {
      animation: fade-in 1.5s both;
    }
    @for $i from 1 to 4 {
      &:nth-child(#{$i}) {
        animation-delay: $i * 0.3s;
      }
    }
  }
}

.icon--headLayered {
  margin-left: 3em;
  width: 7em;
  opacity: 0;
  animation: fade-in 1s 4s both;
  svg {
    max-height: none;
    height: auto;
    width: 100%;
  }
  .head {
  }
  [class^="cog"] {
    animation: rotate-cog 5s 4s both;
  }
  .cog {
    opacity: 1;
    &-1 {
      transform-origin: 23px 41px;
      transform-origin: 42% 55.4%;
    }
    &-2 {
      transform-origin: 56.1% 14.6%;
    }
    &-3 {
      transform-origin: 85.1% 31.9%;
    }
  }
  @keyframes rotate-cog {
    to {
      transform: rotate(360deg);
    }
  }
}
