@import "./scss/vars/all";
@import "./scss/base/all";
@import "./scss/layout/all";
@import "./scss/components/all";
@import "continuum-quiz/collection/components/continuum-quiz/continuum-quiz";
$ccr-size: 2em;
$ccr-color-outer: $brand-slate;
$ccr-color-inner: $brand-slate;
$ccr-background: #fff;
$ccr-border-width: 2px;
$ccr-border-width-focus: $ccr-border-width + 1px;
$ccr-border-radius-checkbox: 2px;
$ccr-icon-scale-checkbox: 70%;
$ccr-icon-scale-radio: 45%;
@import "custom-checkbox-radio-scss";

// Last minute client amend for certain pages :"Space between first sentence and stat needs to be reduced by half"
.shift-up {
  @media (min-width: 960px) {
    margin-top: -2em !important;
  }
}
.shift-down {
  @media (min-width: 960px) {
    margin-top: 2em !important;
  }
}
.h4x0r-menu {
  position: fixed;
  padding: 1em;
  right: 0;
  top: 0;
  font-size: 1rem;
  background: black;
  border: 0.5em inset orange;
  z-index: 99999;
  color: orange;
  opacity: 0.2 !important;
  transition: opacity 0.1s ease-in-out;
  &:hover {
    opacity: 0.8 !important;
  }
  header {
    display: block;
    margin: 0 0 1em;
    font-size: 1.4em;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5em;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  > ul {
    column-count: 2;
    column-gap: 1em;
    > li {
      margin: 0 0 1em;
      font-weight: bold;
    }
  }
  ul ul {
    padding: 0 0 0 1em;
    font-weight: normal;
    display: inline-block;
  }
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    display: block;
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
}
