.video-wrapper {
  position: relative;
  height: 1px;
  padding-bottom: percentage(1080 / 1920);
  margin-bottom: 2em;
  video {
    position: absolute;
    width: 100%;
    max-height: 100%;
  }
}
