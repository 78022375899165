.facts {
  display: flex;
  // flex-flow: column;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  list-style-type: none;
  // // @media (min-width: 820px) {
  // // flex-flow: row;
  @media (min-width: 820px) {
    flex-wrap: nowrap;
  }
}
@mixin undo-bullet-style {
  position: relative;
  border-radius: 0;
  width: auto;
  height: auto;
  margin: 0 auto;
  line-height: 1;
  background: none !important;
  content: none !important; // IE11 spec
}
.fact {
  // display: flex;
  display: block; // omgosh, this is key! for IE11 that is, even though it *shouldn't* make a diff within a flex parent
  flex-flow: column;
  padding: 0;
  margin: 0em 0.5em 2em;
  text-align: left;
  width: auto;
  &,
  *,
  blockquote {
    max-width: 270px;
    [data-facts-count="2"] & {
      max-width: 470px;
    }
  }
  @media (min-width: 820px) {
    margin: 0em 2em 0;
    // max-width: 33%;
  }
  &::before {
    @include undo-bullet-style;
  }
  h3,
  blockquote {
    line-height: 1.17;
    margin: 1em 0 1em;
    font-size: 1.5em;
    font-weight: 700;
  }
  blockquote {
    padding: 0;
    @media (min-width: 820px) {
      min-height: 4.6em; // /stress-awareness/modules/2
    }
  }
  cite {
    &,
    a {
      // color: rgba(white, 0.75);
      color: $neutral-neutral;
    }
    border-color: rgba(white, 0.25);
    padding-left: 1em;
    text-align: left;
  }
  &[class*="--"]::before,
  .pie,
  [class^="icon"],
  > h3 {
    display: flex;
    width: 90%;
    height: 10rem;
    max-width: 8em;
    justify-content: center;
    align-items: center;
    margin: 0 auto 2em;
    @media (min-width: 820px) {
      height: 13rem;
    }
  }
  &[class*="--"]::before,
  > h3 {
    font-size: 4em;
    text-align: center;
  }
  > h3,
  > h4 {
    margin: 0 !important;
    width: 100%;
  }
  > h4 {
    text-align: center;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  strong {
    font-size: 3em;
    color: $neutral-neutral;
  }
}
.sum {
  margin: 0.5em 0 1em;
  padding: 0;
  font-size: 1.5em;
  color: $neutral-neutral;
  font-weight: 500;
  line-height: 1.3;
  flex-grow: 1;
  display: flex;
  align-items: center;
  // align-self: flex-start;
  // align-items: space-around;
  justify-content: center;
  text-align: center;
  // height: 100%;
  // height: auto;
  &::before {
    @include undo-bullet-style;
    margin: 2em 0 0;
    font-size: 2em;
    font-weight: bold;
    color: $brand-pool;
    content: ""; // mobiles need a little gap, at least
    @media (min-width: 820px) {
      padding: 0 0.5em;
      margin: 3.5em auto 0;
      content: none;
    }
  }
  > div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;
    &,
    * {
      // < very important for IE
      max-width: 7em;
    }
    @media (min-width: 820px) {
      justify-content: flex-start;
    }
  }
  svg {
    height: auto;
  }
  &.-plus,
  &.-equals {
    display: flex;
    align-items: center;
    @media (min-width: 820px) {
      justify-content: space-between;
    }
  }
  &.-plus::before {
    content: "+";
  }
  &.-equals::before {
    content: "=";
  }
  &.-equals {
    font-weight: bold;
    // color: $brand-gold;
    @include apply-theme-to-attribute(color);
  }
}
.sum-icon {
  width: 2em;
  margin-bottom: 1em;
  align-self: center;
  @media (min-width: 820px) {
    width: 4em;
  }
  + span {
    margin: auto 0;
  }
}
