main {
  padding-top: 0.5em;
  &.animation-trigger {
    opacity: 0;
  }
  &:not(.animation-trigger) {
    animation: fade-in 1.5s ease-out;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
