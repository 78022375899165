body {
  font: 400 2rem/1.6 "Open Sans", sans-serif; // Add separate rem font-size value if supporting <=IE10, where rems aren't supported in font shorthand
  @include responsive-value(
    $size-min: 12px,
    $size-max: 16px,
    $width-min: 300px,
    $width-max: 1420px,
    $property: "font-size"
  );
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
  line-height: 1.17;
  color: $brand-blue;
}
h1 {
  font-size: 2.3em;
}
@mixin pill($fg, $bg) {
  padding: 0.7em 0.6em 0.7em 1.5em;
  background: $bg;
  color: $fg;
  border-radius: 0.4em;
  line-height: 1;
  transition: all 0.1s ease-out;
  border: 1px solid $neutral-neutral;
}
@mixin chevron($color) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &::after {
    display: inline-block;
    width: 0.5em;
    height: 0.9em;
    content: svg(
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16.3 28.7" width="100%" height="100%"><polyline points="2 2 14.3 14.3 2 26.7" fill="none" stroke="#{$color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/></svg>'
    );
  }
}
[data-counter] {
  position: relative;
  padding-left: 0.5em;
  margin-left: 1em;
  &::before,
  &::after {
    position: absolute;
    left: 0;
    top: 50%;
    width: 1.4em;
    height: 1.4em;
    transform: translate(-50%, -50%);
    font-size: 2em;
    border-radius: 5em;
  }
  &::before {
    content: "";
    padding: 0.06em;
    border: 1px dashed orange;
    box-sizing: content-box; // Holy heck, an actual bonefide use for content box in 2019!!
    z-index: -1;
    @include apply-theme-to-attribute(border-color);
  }
  &::after {
    display: flex;
    justify-content: center;
    align-items: center;
    content: attr(data-counter);
    background: $brand-orange;
    color: white;
    @include apply-theme-to-attribute(background-color);
  }
  &.disabled {
    a {
      pointer-events: none;
    }
    ::after {
      display: none;
    }
  }
}
// Never extend typography! it always bites us right in the proverbial
@mixin h2 {
  margin-top: 0;
  margin-bottom: 0.7em;
  font-size: 2em;
  color: $brand-blue;
  font-weight: 400;
}
h2 {
  @include h2;
  > * {
    @include pill(white, $brand-blue);
    display: block;
  }
  a:hover,
  a:focus {
    @include pill($brand-blue, white);
  }
  h1 + & {
    font-size: 1.2em;
    margin-bottom: 2em;
  }
  a {
    // ¯\_(ツ)_/¯ sometimes links
    @include chevron(white);
    &:hover,
    &:focus {
      @include chevron($brand-blue);
    }
  }
}
h3,
h4,
h5 {
  margin: 2em 0 1em;
}
h3 {
  font-size: 1.6em;
}
h4 {
  font-size: 1.4em;
  margin-bottom: 0.75em;
  .graphic_text & {
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }
}
h5 {
  font-size: inherit;
}
// Endless type amends, just, to the point of insanity
main > .wrapper > .inner h3:first-child {
  margin-top: 0;
}
a {
  text-decoration-skip: ink;
  p & {
    color: $brand-blue;
  }
}
p {
  margin: 0 0 1em;
}
main {
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0 0 2em 0;
    + p > cite {
      margin-top: -1.5em; // as above
    }
  }
  li {
    position: relative;
    animation: slide-in 1s forwards;
    opacity: 0;
    align-items: flex-start;
    padding-left: 1em;
    margin-bottom: 0.5em;
    &:not([data-prefix])::before {
      position: absolute;
      content: "";
      flex-shrink: 0;
      left: 0;
      width: 0.2em;
      height: 0.2em;
      margin-top: 0.8em;
      // margin-left: -1em;
      // // IE11 multi-column fix
      // @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      //   margin-left: -0.5em;
      // }
      background-color: $brand-orange;
      @include apply-theme-to-attribute(background-color);
      border-radius: 50%;
    }
    @for $i from 0 to 30 {
      &:nth-child(#{$i + 1}) {
        animation-delay: $i * 0.1s;
      }
    }
    h5 {
      padding: 0;
      margin: 0;
    }
  }
  ul ul {
    margin-top: 0.5em;
    margin-left: 2em;
    li::before {
      background-color: $brand-slate !important; // override theme
    }
  }
}
.columns--2 {
  @media (min-width: 769px) {
    columns: 2;
    > li {
      margin-right: 2em;
    }
  }
}
// Currently only for the .graphic.-narrow
.graphic-header {
  // width: 53%;
  margin-top: 0;
  text-align: center;
  // This'll never be exactly right, for flex-grow reasons, but close enough is near enough!
  @media (min-width: 1080px) {
    // -a
    width: calc(200% - 1400px);
    min-width: 460px;
    max-width: 580px;
    &.-b {
      min-width: 540px;
      max-width: 632px;
    }
  }
}
