//
// GUIDELINES
// https://brand.unumux.com/unum/digital.html
//
// Primary
$brand-blue: #015193;
$brand-slate: #5a7184;
// Primary Tints
$brand-blue-dark: #01335c;
$brand-blue-light: #0471cc;
$brand-slate-dark: #435463;
$brand-slate-light: #758da1;
// Secondary
$brand-pool: #47b5bd;
$brand-lawn: #8cc442;
$brand-gold: #fac833;
// Tertiary
$brand-orange: #e89527;
$brand-yellow-green: #c1cf01;
$brand-sky: #0376bb;
// Neutral / base
$neutral-dark: #525252;
// Custom..
$neutral-neutral: #f0efe3;
$neutral-blue: #cee3f1;
$neutral-yellow: rgba($brand-gold, 0.1);
/*
Note: Unum Slate (#5E88A1) text color may ONLY be used as title heading, heading, subheading, or copy above 19px/semibold. Unum Slate (#5E88A1) as a background must use white (#FFFFFF) text. The dark neutral (#525252) may be used in all copy.
*/

$themes: (
  cancer-in-the-workplace: $brand-orange,
  stress-awareness: $brand-lawn,
  sickness-absence: $brand-pool,
  // musculoskeletal-disorders: adjust-color($brand-gold, $lightness: -12%),
  musculoskeletal-disorders: $brand-gold,
  bereavement: $brand-sky,
  mental-health: $brand-yellow-green,
);
// Override MSD text colour in SVGs as it's far from AA contrast
.-musculoskeletal-disorders [class*="svg--"] text[data-fill="theme"] {
  fill: $neutral-dark !important;
}
