.icon--calendar {
  path,
  rect {
    animation: fade-in 1s both;
    @for $i from 0 to 40 {
      &:nth-child(#{$i}) {
        opacity: 0;
        animation-delay: (0.5s + $i * 0.08);
      }
    }
  }
  .hold-animation & {
    path,
    rect {
      animation-play-state: paused;
    }
  }
}
