.content-links {
  display: flex;
  flex-flow: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
  li {
    display: flex;
    width: 33%;
  }
  a {
    width: 100%;
    min-height: 300px;
  }
  // Use the theme colours on an element (see utilities)
}
.content-link {
  // @include apply-theme-to-attribute(background-color);
  color: white;
  &:hover,
  &:focus {
    background-color: teal;
  }
}
.download-link {
  display: block;
  margin-bottom: -0.3em;
  line-height: 1.2;
  font-size: 0.9em;
  color: $brand-blue;
  &::before {
    display: inline-block;
    width: 1em;
    margin-right: 0.5em;
    @include svg(
      '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-download fa-w-16 fa-2x"><path fill="#{$brand-slate}" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>'
    );
  }
}
.external-link {
  &::before {
    display: inline-block;
    width: 1em;
    margin-right: 0.5em;
    @include svg(
      '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-external-link-alt fa-w-18 fa-2x"><path fill="#{$brand-slate}" d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z" class=""></path></svg>'
    );
  }
}
