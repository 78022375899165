.page {
  // background: url("images/swoop.png");
  // background-image: svg(
  //   '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="352.72px" height="103.15px" viewBox="0 0 352.72 103.15"><style type="text/css">.st0{fill:url#swoop-gradient);}</style><linearGradient id="swoop-gradient" x2="1" y2="1"><stop offset="0%" stop-color="#EBF7FB" /><stop offset="100%" stop-color="#CCECF5" /></linearGradient><path class="st0" d="M352.72,0H0c19.34,29.33,68.97,103.15,154.21,103.15l0,0.01C220.78,102.3,258.15,66.55,352.72,0"/></svg>'
  // );
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='352.72' height='103.15'%3E%3ClinearGradient id='a' x2='1' y2='1'%3E%3Cstop offset='0%25' stop-color='%23EBF7FB'/%3E%3Cstop offset='100%25' stop-color='%23CCECF5'/%3E%3C/linearGradient%3E%3Cpath d='M352.72 0H0c19.34 29.33 68.97 103.15 154.21 103.15v.01C220.78 102.3 258.15 66.55 352.72 0' fill='url(%23a)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1000px auto;
  background-position: center 0;
  // Doesn't currently work, but might do one day:
  transition: background-size 0.3s ease-out;
  @media (min-width: 769px) {
    background-size: auto calc(70vw - #{29em * 0.7});
  }
  @media (min-width: 1090px) {
    background-size: auto 460px;
  }
  @media (min-width: 1600px) {
    background-size: calc(100% + 300px) auto;
  }
  @media (min-width: 1900px) {
    background-size: auto 622px;
  }
  &.page--site {
    background-size: auto 78%;
  }
}
.banner {
  // We may have to do this as a pseudo element on main,
  // as the module pages display it differently to the listing (where it fills screen)
  position: relative;
  z-index: 0;
  .inner {
    margin-top: calc(6em + 1px);
  }
  .breadcrumbs + .inner {
    margin-top: 0;
  }
  h1,
  h2,
  h3,
  p {
    @media (max-width: 1200px) {
      max-width: 370px;
    }
    &:empty {
      display: none;
    }
  }
  h1 {
    margin-top: 1.5em;
  }
  .inner > p {
    &:first-of-type {
      font-size: 1.2em;
      line-height: 1.5;
      font-weight: 600;
    }
  }
  .breadcrumbs + h1 {
    margin-top: 0;
  }
  .wrapper {
    // Matching PRECISELY to the banner img proportional heights
    $img-ratio: (471 / 643);
    @media (min-width: 769px) {
      min-height: calc((100vw - 29em) * #{$img-ratio});
    }
    @media (min-width: 1090px) {
      min-height: calc(643px * #{$img-ratio});
    }
    @media (min-width: 1600px) {
      // min-height: 37em;
      min-height: calc(45vw * #{$img-ratio});
    }
    @media (min-width: 1900px) {
      min-height: calc(850px * #{$img-ratio});
    }
  }
  .img {
    .page--site & {
      // Only fade in after a delay on initial load, not changing from index to a module page
      opacity: 0;
      animation: fade-in 1.5s 0.5s forwards;
    }
    margin-left: 12%;
    // width: 100%;
    width: calc(100% - 29em);
    max-width: 643px;
    padding-bottom: percentage(471 / 643);
    background: url("../../../public/images/banners/default.png");
    background-repeat: no-repeat;
    background-size: contain;
    + div {
      position: relative;
    }
    .-cancer-in-the-workplace & {
      background-image: url("../../../public/images/banners/cancer-in-the-workplace.png");
    }
    .-stress-awareness & {
      background-image: url("../../../public/images/banners/stress-awareness.png");
    }
    .-sickness-absence & {
      background-image: url("../../../public/images/banners/sickness-absence.png");
    }
    .-musculoskeletal-disorders & {
      background-image: url("../../../public/images/banners/msd.png");
    }
    .-bereavement & {
      background-image: url("../../../public/images/banners/bereavement.png");
    }
    .-mental-health & {
      background-image: url("../../../public/images/banners/mental-health.png");
    }
  }
  p {
    color: $brand-blue;
  }
  @media (min-width: 769px) {
    .img {
      // .masthead-logo {
      position: absolute;
      right: 0;
      top: 0;
      margin-left: 0;
      z-index: 0;
    }
    // .masthead-logo {
    //   max-width: calc(100% - 29em);
    // }
    .wrapper {
      display: flex;
      .inner {
        // Longest h2 is currently:
        // "Supporting An Employee's Return to Work" /sickness-absence/modules/4
        width: 28em;
      }
    }
  }
  @media (min-width: 1600px) {
    .img {
      // .masthead-logo {
      width: 45%;
      max-width: 850px;
    }
  }
}
.affiliate-logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    max-width: 45%;
  }
}
.affiliate-logo {
  width: 230px;
  margin-bottom: 1em;
  // float: right;
  // margin-right: -10em;
}
