.svg--leaf-5 {
  .leaf {
    // Animation moved to greensock
    // opacity: 0;
    // $animation-duration: 1;
    // transform-origin: 88.7px 144.7px;
    fill: $brand-lawn;
    // animation: opacity-in #{$animation-duration}s both;
    // @for $i from 1 to 4 {
    //   &:nth-child(#{$i}) {
    //     animation-delay: $i * 0.2s;
    //   }
    // }
  }
  // &.hold-animation {
  //   .leaf {
  //     animation-play-state: paused;
  //   }
  // }
  // @keyframes opacity-in {
  //   to {
  //     opacity: 1;
  //   }
  // }
}
