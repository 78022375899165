@import "../vars/all";
.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba($neutral-neutral, 0.5);
  // fade in animate
  opacity: 0;
  animation: fade-in 1s forwards;
  ._inner {
    background: white;
    padding: 2em 3em;
    width: 38em;
    max-width: 90%;
    border-radius: 0.5em;
    box-shadow: 0.25em 0.25em 0.5em rgba(0, 0, 0, 0.4);
  }
}
