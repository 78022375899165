.pie {
  position: relative;
  width: 7em;
  margin: 0 auto;
  // Polo hole
  // &::after {
  //   $size: 3em;
  //   content: "";
  //   background: $brand-blue;
  //   border-radius: 50%;
  //   width: $size;
  //   height: $size;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   margin-top: -$size * 0.5;
  //   margin-left: -$size * 0.5;
  // }
  path {
    blockquote & {
      opacity: 0;
    }
    &:not(.triggered) {
      visibility: hidden;
    }
    &.triggered {
      visibility: visible;
    }
    .oblong & {
      stroke: $brand-blue;
    }
    // Main piece
    &[fill="primary"] {
      @include apply-theme-to-attribute(fill);
      &.triggered {
        opacity: 0;
        animation: scale-fade-in 1s forwards;
        animation-delay: 1s !important;
      }
    }
    &[fill="secondary"] {
      fill: $brand-blue;
      &.triggered {
        opacity: 0;
        animation: rotate-fade-in 1s forwards;
        animation-delay: 0s !important;
      }
      .oblong & {
        fill: white;
      }
    }
  }
}
