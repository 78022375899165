.related {
  list-style: none;
  padding-left: 0;
  margin: 0 0 2em;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 10;
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 3px; // Account for <li> padding-bottom (to provide space when the <li>s wrap)
    right: 100%;
    top: 0;
    width: 100vw;
    background-color: #c5e9f3;
    display: block;
  }
  li {
    display: list-item;
    width: 100%;
    margin: 0;
    padding: 0 0 3px;
    @media (min-width: 769px) {
      min-height: 8em; // two lines being min height, to solve clients' confusions
    }
    &::before {
      content: none !important;
    }
  }
  a {
    padding: 0.9em 1.1em 1.1em;
    height: 100%;
    margin-right: 3px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: background-color 0.5s, color 0.5s;
    background-color: $brand-blue;
    &:not(:hover) {
      text-decoration: none;
    }
    &:hover {
      background-color: darken($brand-blue, 10);
    }
  }
  h3 {
    margin: 0 0 auto;
    padding-bottom: 1.4em;
    color: #c5e9f3;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0.05em;
  }
  .wrapper {
    position: relative;
    z-index: 0;
  }
  @media (min-width: 500px) {
    li {
      width: 50%;
    }
  }
  @media (min-width: 769px) {
    li {
      width: 33.3%;
    }
  }
}
