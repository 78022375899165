.accreditation {
  // font-size: 0.9em;
  max-width: 40em;
  img {
    flex-shrink: 0;
    width: auto;
    height: auto;
    // max-width: 30%;
    max-width: 10em;
    margin-right: 3%;
    margin-bottom: 3%;
  }
  p {
    flex-grow: 1;
  }
  @media (min-width: 350px) {
    display: flex;
    align-items: flex-start;
  }
}
