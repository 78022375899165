//
// Styles that apply to ALL graphic SVGs
//
.graphic {
  @media (min-width: 1080px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 430px; // <- stop related links moving around
    > div {
      flex-grow: 1;
      &,
      svg {
        // min-height: 430px; // <- IE11 fix
      }
    }
  }
  &.-narrow {
    * {
      // border: 1px solid hotpink;
    }
    svg {
      @media (min-width: 1080px) {
        max-width: 300px;
        margin-left: auto;
      }
    }
  }
  &.-valign {
    align-items: center;
    justify-content: center;
  }
  // .test {
  // // IE11 style scale #oldschool
  //   // transform="translate(0 300) scale(2 2) translate(0 -300)"
  //   transform: translate(0, 300) scale(2, 2) translate(0, -300);
  // }
  svg {
    // See _svg.scss
  }
  .ring {
    // transform: rotate(-51deg);
    // // Firefox fix - transform-origins need to be in the CSS, not markup
    // &[cx="945.2"][cy="463"] {
    //   transform-origin: 945.2px 463px;
    // }
    // &[cx="1039"][cy="463"] {
    //   transform-origin: 1039px 463px;
    // }
    // &[cx="1039"][cy="728"] {
    //   transform-origin: 1039px 728px;
    // }
    // &[cx="964.1"][cy="463"] {
    //   transform-origin: 964.1px 463px;
    // }
    // animation: spin 50s infinite forwards linear;
    // @keyframes spin {
    //   from {
    //     transform: rotate(-51deg);
    //   }
    //   to {
    //     transform: rotate(360deg);
    //   }
    // }
    // &:not([transform]) {
    opacity: 0; // greensock instead at this point
    //   animation: fade-in 2s linear forwards;
    //   animation-delay: 2s;
    // }
  }
  .icon {
    // animation: fade-scale-in 1s 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
    // transform-origin: center;
    opacity: 0;
  }
  .background {
    transform-origin: center;
    animation: background-in 2s 2.5s linear forwards;
  }
  .title {
    transform: scale(1.1);
    transform-origin: center;
    path,
    rect {
      stroke: $brand-blue-dark;
    }
    rect {
      // default style is actually current only RESILIENCE on leaf diagram http://localhost:3000/stress-awareness/modules/7
      // the rest are overridden by styles within SVGs
      fill: $neutral-blue;
      stroke: $neutral-blue;
    }
    text {
      fill: $brand-blue-dark;
    }
  }
  a {
    circle,
    path,
    polygon {
      // text { // Having to disable text transition, as greensock pulsing is re-triggering it which is awful
      transition: all 0.6s;
    }
    cursor: pointer;
    user-select: none;
  }
  text {
    text-transform: uppercase;
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    letter-spacing: 0.085em;
    user-select: none;
  }
  .point {
    position: relative;
    z-index: 1;
  }
}
.graphic_text {
  .graphic.-narrow & {
    // width: 70%;
    // flex-grow: 0;
    // flex-shrink: 1;
    max-width: 600px;
  }
  &.-bubble {
    > div {
      position: relative;
      display: inline-block;
      max-width: 400px; // '"how can someone dying..'
      padding: 1em 3em 1em 2em;
      @include apply-theme-to-attribute(background-color);
      border-radius: 10em;
      h3,
      p {
        color: white;
        margin: 0;
      }
      h3 {
        font-size: 1em;
        text-transform: uppercase;
      }
      &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 2em;
        height: 2em;
        margin-top: -2em * 0.5;
        background: inherit;
        content: "";
        transform: rotate(45deg) skew(-16deg, -16deg);
        z-index: -1;
      }
    }
  }
  > * {
    opacity: 0;
    animation: slide-in 1s both;
  }
  h2,
  h3 {
    margin-top: 0;
  }
  @media (min-width: 1080px) {
    flex-shrink: 0;
    width: 50%;
    max-width: 25em;
    margin-left: 3em;
  }
}
