//
// Styles for individual SVGs
[class*="svg--"] {
  margin-bottom: 4%;
  // margin-top: 4%;
  width: 100%;
  max-width: 46em;
  font-family: "Ubuntu", sans-serif;
  font-size: 2.8em; // 3 lines of text
  // Edge+ (breaks IE11 on hotspot hover)
  @supports (position: sticky) {
    height: auto;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // min-height: 30vw; // <- IE11 fix
    // ^ No longer need this after setting all width/heights on SVGs
  }
  max-height: 320px;
  // Taller SVGs:
  &.svg--hands-5,
  &.svg--persons,
  &.svg--grief,
  &.svg--grief-3,
  &.svg--msds,
  &.svg--person,
  &.svg--back,
  &.svg--managing-5,
  &.svg--managing-numeric-5,
  &.svg--think,
  &.svg--psych-8,
  &.svg--psychology,
  &.svg--persons-2,
  &.svg--managing-procon-4,
  &.svg--back-4,
  &.svg--back-5,
  &.svg--managing-new-5,
  &.svg--clock-4,
  &.svg--desk {
    max-height: 430px;
  }
  // Fixing smaller SVGs, scaling them up because we've no time to edit viewboxes
  &.svg--managing-new-5,
  &.svg--back-4,
  &.svg--back-5,
  &.svg--shield,
  &.svg--leaf-5 {
    @media (min-width: 768px) {
      min-height: 400px;
    }
  }
  @media (max-width: 768px) {
    min-height: 30vw; // <- IE11 fix
    &.svg--back,
    &.svg--msds,
    &.svg--person,
    &.svg--grief,
    &.svg--grief-3,
    &.svg--managing-5,
    &.svg--think,
    &.svg--psych-8,
    &.svg--persons,
    &.svg--managing-procon-4,
    &.svg--back-4,
    &.svg--back-5,
    &.svg--managing-new-5 {
      min-height: 320px; // <- IE11 fix (maybe obsolete?)
    }
  }
  &:hover .ring {
    // Pause on hover anywhere. Not as enjoyable but much more forgiving for SVG markup
    animation-play-state: paused;
  }
  path,
  circle,
  text,
  polygon {
    &[data-fill="theme"] {
      @include apply-theme-to-attribute(fill);
    }
    &[data-stroke="theme"] {
      @include apply-theme-to-attribute(stroke);
    }
  }
  .header {
    text-transform: none;
    font-weight: 600;
    line-height: 1.17;
    letter-spacing: normal;
    fill: $brand-blue;
  }
}
// .svg--grief-3 {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(3);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-3;
// }
// .svg--psychology {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(3);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-bubbles;
// }
// .svg--bed {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(4);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-4;
// }
// .svg--ekg {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(4);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-4;
// }
// .svg--leaf {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(4);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-4;
// }
// .svg--prevention {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(4);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-4;
// }
// .svg--health {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(4);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-4;
// }
// .svg--psych-4 {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(4);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-4;
// }
// .svg--strategy {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(4);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-4;
// }
// .svg--hands-4 {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(4);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-4;
// }
// .svg--hands-5 {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(5, 0.5);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-5;
// }
// .svg--back {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(5, 0.53);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-5;
// }
// .svg--managing-5 {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(5, 0.5);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-5;
// }
.svg--managing-numeric-5 {
  // @include hotspot-hover-greensock;
  // // @include hotspot-sequencial-fade-in(5, 0.5);
  // @include hotspot-hover-pulse;
  // @include hotspot-greensock;
  // @include graphic-points-5;
  text {
    font-size: 2.6em;
    text-anchor: right; // Because IE11/Edge are terrible
  }
}
// .svg--managing-6 {
//   @include hotspot-hover-greensock;
//   // @include hotspot-sequencial-fade-in(6, 0.4);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-6;
// }
// .svg--psych-8 {
//   // @include hotspot-sequencial-fade-in(8, 0.4);
//   @include hotspot-hover-pulse;
//   @include hotspot-greensock;
//   @include graphic-points-8;
// }
.svg--person {
  g.person {
    opacity: 0;
  }
  @include person-hotspots;
  // @include hotspot-sequencial-fade-in(99, 0.4);
  @include hotspot-greensock;
  @include hotspot-hover-pulse;
}
.svg--persons-2 {
  @include person-hotspots-colouring-new;
}
.svg--managing-procon-4 {
  // @include hotspot-hover-greensock;
  // @include hotspot-greensock;
  .st3 {
    @include apply-theme-to-attribute(fill);
  }
  .st6,
  .st9 {
    @include apply-theme-to-attribute(stroke);
  }
}
.svg--desk {
  g.point {
    scale: 0.75;
  }
}
.animations-hotspots-and-points {
  @include hotspot-hover-greensock;
  // @include hotspot-sequencial-fade-in(6, 0.4);
  @include hotspot-hover-pulse;
  @include hotspot-greensock;
  // @include graphic-points-6;
}
.animations-no-jiggle .hotspot {
  pointer-events: none;
}
.animations-just-hotspots {
  @include hotspot-hover-greensock;
  @include hotspot-greensock;
}
