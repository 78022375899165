.refs {
  cite {
    max-width: none;
  }
  h3 {
    margin-top: 0;
  }
  li {
    padding-left: 0;
    margin-bottom: 0.3em;
    line-height: 1.2;
    list-style-type: none;
    main &::before {
      content: none;
    }
  }
  a {
    font-size: 0.8em;
    color: $brand-blue;
  }
}
